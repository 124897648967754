import {
  each,
  map,
  lastIndexOf,
  assign,
  upperFirst,
  has, orderBy,
} from 'lodash';
import { configApi, dataApi } from '@/api';
import router from '@/router';
import types from './mutationTypes';

const inferHeadersFromTicketPayload = (ticketPayload) => {
  let haveStatus = false;
  const ticketProps = map(ticketPayload, (value, key) => {
    if (key === 'status') {
      haveStatus = true;
      return {
        id: 'icon',
        name: 'icon',
      };
    }
    return {
      id: key,
      name: key,
    };
  });
  if (haveStatus) {
    ticketProps.push({
      id: 'status',
      name: 'status',
    });
  }
  return ticketProps;
};

export default {
  setConfig({ commit }, payload) {
    commit(types.SET_CONFIG, payload);
  },
  setOffer({ commit }, payload) {
    commit(types.SET_OFFER, payload);
  },
  setUser({ commit }, payload) {
    commit(types.SET_USER, payload);
  },
  setInfo({ commit }, payload) {
    commit(types.SET_INFO, payload);
  },
  /**
   * Retrieve tenant config and save to app state.
   */
  async getTenantConfig({ state, dispatch }, payload) {
    try {
      const response = await configApi.loadConfig(state.config, payload);
      return response.message;
    } catch (error) {
      dispatch('setInfo', {
        type: 'error',
        message: error.response.data.error || error,
      });
      return null;
    }
  },
  /**
   * Put new config via api and save to app state.
   */
  async updateTenantConfig({ state, dispatch }, { data, channel = 'General' }) {
    let message;
    let type;
    try {
      // eslint-disable-next-line
      const response = await configApi.setConfig(state.config, { data, channel });
      message = 'changesSavedSuccessfully';
      type = 'success';
      return true;
    } catch (error) {
      message = error.response.data.error || error;
      type = 'error';
      return false;
    } finally {
      dispatch('setInfo', { type, message });
    }
  },
  async getData({
    state,
    getters,
    commit,
    dispatch,
  }) {
    const page = router.history.current.name;
    // console.log(' page ', page);
    commit(types.RESET_DATA);
    try {
      const params = { ...state.params };
      if (page === 'tickets') {
        params.betChannel = getters.activeChannel;
      }
      const response = await dataApi.loadData(page, params, state.config);
      if (response[page]?.length === 0) {
        dispatch('setInfo', {
          type: 'info',
          message: 'noDataSelected',
        });
      } else {
        dispatch('setInfo', null);
      }
      commit(types.SET_DATA, response[page]);
      commit(types.SET_PAGINATION, response.pagination);
      commit(types.SET_TOTALS, response.totals);
      if (page === 'tickets' && this.state.data[0]) {
        dispatch('getTicket', state.data[0].barcode);
      }
      dispatch('getFilters', response.filters);
      dispatch('getAndSetTableHeaders', state.selectedHeaders[page]);
    } catch (error) {
      console.log(' getData error ', error.response);
      dispatch('setInfo', {
        type: 'error',
        message: error.response.data.error || error.response.data,
      });
    }
  },
  setTicketsHeaders({ commit }, payload) {
    commit(types.SET_SELECTED_HEADERS, payload);
  },
  resetAllHeaders({ commit }) {
    commit(types.SET_ALL_HEADERS, []);
  },
  /**
   * Creates headers from the ticket keys, based on the fetched ticket payload
   * and sets that header arrays as allHeaders.
   * After that, the tableHeaders are created and set based on aforementioned headers, or
   * is made from the payload argument if passed
   * @param {*} payload
   */
  getAndSetTableHeaders({
    commit, state, getters, dispatch,
  }, payload) {
    // const page = router.history.current.name;
    // commit(types.SET_SELECTED_HEADERS, { page, headers: payload });

    dispatch('resetAllHeaders');
    commit(types.SET_ALL_HEADERS, inferHeadersFromTicketPayload(getters.data[0]));

    const selectedHeaders = [...(payload || state.allHeaders.map((({ name }) => name)))];

    const tableHeaders = selectedHeaders.map((header) => ({
      value: header,
      text: state.translations[header] ? state.translations[header] : header,
      align: 'center',
      sortable: getters.sortableColumns.includes(header),
    }));

    if (payload) {
      tableHeaders[lastIndexOf(selectedHeaders)] = state.addColumn;
    }
    commit(types.SET_HEADERS, tableHeaders);
  },
  getFilters({ commit }, payload) {
    const filters = [];
    each(payload, (filter, index) => {
      if (filter.length > 0) {
        const items = [];
        each(orderBy(filter, 'id'), (value) => {
          items.push({
            id: value.id,
            name: value.name,
          });
        });
        filters.push({ name: index, items });
      }
    });
    commit(types.SET_FILTERS, filters);
  },
  async getTicket({ state, commit }, payload) {
    commit(types.SET_BARCODE, payload);
    const response = await dataApi.loadTicket(payload, state.config);
    commit(types.SET_TICKET, response);
  },
  setTranslations({ commit }, payload) {
    commit(types.SET_TRANSLATIONS, payload);
  },
  updateParams({ commit, state, dispatch }, payload) {
    const allParams = state.params;
    assign(allParams, payload);
    each(Object.keys(payload), (key) => {
      if (allParams[key].length === 0) {
        delete allParams[key];
      }
    });
    commit(types.UPADTE_PARAMS, allParams);
    if (!has(payload, 'exportFormat')) {
      dispatch('getData');
    }
  },
  resetParams({ commit }) {
    commit(types.RESET_PARAMS);
  },
  async exportFile({ state, dispatch, getters }, payload) {
    const page = router.history.current.name;
    const { productName } = state.config;
    try {
      const params = {
        ...state.params,
        betChannel: getters.activeChannel,
        locale: getters.config.locale,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const response = await dataApi.loadData(page, params, state.config, 'blob');
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${productName}${upperFirst(page)}.${payload}`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      dispatch('updateParams', { exportFormat: '' });
    } catch (error) {
      console.log(' error ', error.response);
      dispatch('updateParams', { exportFormat: '' });
    }
  },
  updateActiveChannel({ commit, dispatch }, payload) {
    commit(types.SET_ACTIVE_CHANNEL, payload);
    dispatch('getData');
  },
};
